<template>
    <div>
        <div class="form-control">
            <label for="cf_nome_completo">Nome completo*:</label>
            <input id="cf_nome_completo" name="cf_nome_completo" required="true">
        </div>
        <div class="form-control">
            <label for="cf_nome_do_pai">Nome do pai*:</label>
            <input id="cf_nome_do_pai" name="cf_nome_do_pai" required="true">
        </div>
        <div class="form-control">
            <label for="cf_nome_da_mae">Nome da mãe*:</label>
            <input id="cf_nome_da_mae" name="cf_nome_da_mae" required="true">
        </div>
        <div class="form-control">
            <label for="cf_estado_civil">Estado civil*:</label>
            <select name="cf_estado_civil" id="cf_estado_civil" required="true" v-model="form.estado_civil">
                <option value=""></option>
                <option value="casado">Casado</option>
                <option value="divorciado">Divorciado</option>
                <option value="solteiro">Solteiro</option>
                <option value="uniao_estavel">União estável</option>
                <option value="Viúvo">Viúvo</option>
            </select>
        </div>
        <!-- {{form.estado_civil}} -->
        <div class="form-control" v-if="form.estado_civil == 'casado'">
            <label for="cf_data_do_casamento">Data do casamento*:</label>
            <input id="cf_data_do_casamento" name="cf_data_do_casamento" type="date" required="true" >
        </div>
        <div class="form-control">
            <label for="cf_grau_de_instrucao">Grau de instrução*:</label>
            <select name="cf_grau_de_instrucao" id="cf_grau_de_instrucao" required="true">
                <option value=""></option>
                <option value="ensino fundamental incompleto">Ensino fundamental incompleto</option>
                <option value="ensino fundamental completo">Ensino fundamental completo</option>
                <option value="ensino medio incompleto">Ensino médio incompleto</option>
                <option value="ensino medio completo">Ensino médio completo</option>
                <option value="ensino superior incompleto">Ensino superior incompleto</option>
                <option value="ensino superior completo">Ensino superior completo</option>
                <option value="pos graduação/especialização">Pós-graduação/especialização</option>
                <option value="mestrado">Mestrado</option>
                <option value="doutorado">Doutorado</option>
                <option value="pos doutorado">Pós-doutorado</option>
            </select>
        </div>
        <div class="form-control">
            <label for="cf_sexo">Sexo*:</label>
            <select name="cf_sexo" id="cf_sexo" required="true">
                <option value=""></option>
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
            </select>
        </div>
        <div class="form-control">
            <label for="cf_cor_da_pele">Cor da pele*:</label>
            <select name="cf_cor_da_pele" id="cf_cor_da_pele" required="true">
                <option value=""></option>
                <option value="branca">Branca</option>
                <option value="negra">Negra</option>
                <option value="amarela">Amarela</option>
                <option value="indígena">Indígena</option>
                <option value="parda">Parda</option>
            </select>
        </div>
        <div class="form-control">
            <label for="cf_nacionalidade">Nacionalidade*:</label>
            <select name="cf_nacionalidade" id="cf_nacionalidade" required="true">
                <option value=""></option>
                <option value="brasileira">Brasileira</option>
                <option value="estrangeira">Estrangeira</option>
            </select>
        </div>
        <div class="form-control">
            <label for="cf_estado_de_nascimento">Estado de nascimento:</label>
            <select name="cf_estado_de_nascimento" id="cf_estado_de_nascimento" @change="load_municipios" v-model="form.estado_de_nascimento">
                <option value=""></option>
                <option :value="uf.sigla" v-for="(uf, index) in ufs" v-bind:key="index">{{uf.sigla}} - {{uf.nome}}</option>                
            </select>
        </div>
        <div class="form-control">
            <label for="cf_cidade_de_nascimento">Cidade de nascimento:</label>
            <select name="cf_cidade_de_nascimento" id="cf_cidade_de_nascimento">
                <option value="">Selecione um estado primeiro</option>
                <option :value="retira_acentos(municipio.nome)" v-for="(municipio, index) in municipios" v-bind:key="index">{{municipio.nome}}</option>                
            </select>
        </div>
        <div class="form-control">
            <label for="cf_tem_filhos">Tem filhos?:</label>
            <input type="range" id="cf_tem_filhos" name="cf_tem_filhos" min="0" max="10" v-model="tem_filhos">
            <span>{{tem_filhos}}</span>
        </div>
        <!-- {{form}} -->
    </div>
</template>
<script>
import {mask} from 'vue-the-mask'
import mixins from '@/assets/mixins'

export default {
    directives: {mask},
    mixins: [mixins],
    created(){
    },
    data: () => ({
        tem_filhos: 0,
        ufs: null,
        municipios: null,
        cf_estado_civil:undefined
    }),
    props:{
        form:Object
    },
    beforeCreate () {

       //Carrega os estados       
       var interval = setInterval(() => {
            this.ufs = this.$store.state.ufs;

            if(this.ufs != null){
                clearInterval(interval);
            } 
        }, 1000)

        var interval2 = setInterval(() => {
            this.municipios = this.$store.state.municipios;
                        
            if(this.municipios != null){
                clearInterval(interval2);
            } 
        }, 1000)
        
    },
    methods:{
        
        load_municipios(el){

            var uf = el.target.value;
            var id_uf = null;

            //Loop in all uf's to find id uf
            for (const el_uf in this.ufs) {
                if(uf == this.ufs[el_uf].sigla){
                    id_uf = this.ufs[el_uf].id;
                }
            }

            if(!id_uf){
                alert('Erro ao carregar as uf\'s');
                return false;
            }

            this.axios
                .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id_uf}/municipios`)
                .then(response => {

                    //Check if status that's ok!
                    if(response.status == 200){
                        this.municipios = this.orderStr(response.data, 'nome');
                    }else{
                        alert('Erro ao carregar as uf\'s');
                    }
                })
        }
    }

}
</script>