import Vue from 'vue'
import Vuex from 'vuex'
import mixins from '@/assets/mixins'
// import VueResource from 'vue-resource';

Vue.use(Vuex)
// Vue.use(VueResource)

// Import axios
import axios from 'axios'
import VueAxios from 'vue-axios'
 
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  mixins: [mixins],
  state: {
    ufs: null, // Lista de ufs
    municipios: null, // Lista de municipios
    loading: false
  },
  mutations: {
    "set-ufs"(state, ufs) {
      state.ufs = mixins.methods.orderStr(ufs, 'sigla');
		},
    "set-municipios"(state, municipios) {
      state.municipios = mixins.methods.orderStr(municipios, 'nome');
		},
    "set-loading"(state, loading) {
      state.loading = loading;
		}
  },
  actions: {
    async 'load-ufs'(context) {
      try {
        const response = await Vue.axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        if (response.status === 200) {
          context.commit("set-ufs", response.data);

          return response.data;
        } else {
          alert('Erro ao carregar as uf\'s');
        }
      } catch (error) {
        console.error('Erro ao carregar as uf\'s', error);
      }
    },
    async 'load-municipios'(context) {
      try {
        const response = await Vue.axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/municipios');
        if (response.status === 200) {
          context.commit("set-municipios", response.data);
        } else {
          alert('Erro ao carregar os municípios');
        }
      } catch (error) {
        console.error('Erro ao carregar os municípios', error);
      }
    },
    'change-loading'(context, value){
        context.commit("set-loading", value);
    }
  },
  modules: {
  }
})
