import { render, staticRenderFns } from "./Finalizado.vue?vue&type=template&id=19a11953&scoped=true"
import script from "./Finalizado.vue?vue&type=script&lang=js"
export * from "./Finalizado.vue?vue&type=script&lang=js"
import style0 from "./Finalizado.vue?vue&type=style&index=0&id=19a11953&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a11953",
  null
  
)

export default component.exports