<template>
    <div class="box dependente'">
        <div class="form-control">
            <label for="cf_dependente_nome_completo">Nome completo</label>
            <input :id="'cf_dependente_nome_completo_' + idData" name="cf_dependente_nome_completo" >

        </div>
        <div class="form-control">
            <label for="cf_dependente_data_nascimento">Data Nascimento</label>
            <input :id="'cf_dependente_data_nascimento_' + idData" name="cf_dependente_data_nascimento" type="date" >
        </div>
        <!-- <div class="form-control">
            <label for="cf_dependente_parentesco">Parentesco</label>
            <input :id="'cf_dependente_parentesco_' + idData" name="cf_dependente_parentesco" >
        </div> -->
         <div class="form-control">
            <label for="cf_dependente_parentesco">Parentesco</label>
            <select :id="'cf_dependente_parentesco_' + idData" name="cf_dependente_parentesco" required="true" >
                <option value=""></option>
                <option value="01">Cônjuge</option>
                <option value="02">Companheiro(a) com o(a) qual tenha filho ou viva há mais de  5  (cinco) anos ou possua Declaração de União Estável</option>
                <option value="03">Filho(a) ou enteado(a)</option>
                <option value="04">Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau</option>
                <option value="06">Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial</option>
                <option value="07">Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, universitário(a) ou cursando escola técnica de 2° grau, do(a) qual detenha a guarda judicial</option>
                <option value="09">Pais, avós e bisavós</option>
                <option value="10">Menor pobre do qual detenha a guarda judicial</option>
                <option value="11">A pessoa absolutamente incapaz, da qual seja tutor ou curador</option>
                <option value="12">Ex-cônjuge</option>
                <option value="99">Agregado/Outros</option>
            </select>
        </div>
        <div class="form-control">
            <label for="cf_dependente_sexo">Sexo</label>
            <select :id="'cf_dependente_sexo_' + idData" name="cf_dependente_sexo" required="true" >
                <option value=""></option>
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
            </select>
        </div>
        <div class="form-control">
            <label for="cf_dependente_cpf">CPF</label>
            <input :id="'cf_dependente_cpf_' + idData" name="cf_dependente_cpf" v-mask="'###.###.###-##'" >
        </div>
        <div class="form-control">
            <label for="cf_dependente_ir">Dependente IR?</label>
            <select :id="'cf_dependente_ir_' + idData" name="cf_dependente_ir" required="true" >
                <option value=""></option>
                <option value="sim">Sim</option>
                <option value="nao">Não</option>
            </select>
        </div>        
    </div>
</template>
<script>
import {mask} from 'vue-the-mask'

export default {
    props:{
        idData:Number,
        dependente: Object
    },
    directives: {mask}

}
</script>
<style lang="scss" scoped>
    .box{
        border: 1px solid #c1c7d0;
        padding: 10px;
        border-radius: 5px;
        margin: 10px 0;
    }
</style>
<style lang="scss" >
    .box{
        // float: left;
    }
</style>