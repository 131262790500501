<template>
  <div class="admission-confirmation">
    <div class="admission-confirmation__content">
      <h1>Parabéns, você concluiu sua inscrição!</h1>
      <p>Agora que você terminou o formulário de admissão, sua inscrição foi enviada com sucesso.</p>
      <p>Fique de olho no seu e-mail para futuras atualizações sobre o processo de admissão.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdmissionConfirmation'
}
</script>

<style scoped>
.admission-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.admission-confirmation__content {
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admission-confirmation h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.admission-confirmation p {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}
</style>
