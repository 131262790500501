import Vue from 'vue'
import VueRouter from 'vue-router'
import Admissao from '../views/Admissao.vue'
import AdmissaoFinalizado from '../components/admissao/Finalizado.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/candidato/123'
  },
  {
    path: '/candidato/:key',
    name: 'Admissao candidato',
    component: Admissao,
    meta: {
      title: 'Jira - Admissão Candidato',
      role: 'candidato'
    }
  },
  {
    path: '/finalizado',
    name: 'Admissao candidato finalizado',
    component: AdmissaoFinalizado,
    meta: {
      title: 'Jira - Formulário finalizado'   
    }
  },
  {
    path: '/dp/:key',
    name: 'Admissao dp',
    component: Admissao,
    meta: {
      title: 'Jira - Admissão DP',
      role: 'dp'
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
