<template>
    <div id="CandidatoForm" class="container">
        <div class='box-loading'>
            <div class="loading" v-if="$store.state.loading"><img src="@/assets/loading.gif" alt="Carregando..."
                    title="Carregando..."> </div>
            <h2>Formulário de admissão</h2>
        </div>

        <form @submit="submit">
            <h3>Dados Pessoais</h3>
            <InputDadosPessoais :form="form.dados_pessoais" />

            <h2>Dados de endereço</h2>
            <InputDadosEndereco />

            <h2>Dados de documentação</h2>
            <InputDadosDocumentacao />

            <h2>Dados bancários</h2>
            <InputDadosBancarios />

            <h2>Dependentes Legais</h2>
            <div class="form-control">
                <label for="cf_count_dependentes">Dependentes:</label>
                <input type="range" id="cf_count_dependentes" name="cf_count_dependentes" min="0" max="10"
                    v-model="count_dependentes" @change="add_dependente()">
                <span>{{ count_dependentes }}</span>
            </div>

            <div class="box_dependentes">
                <InputDependente v-for="(input, index) in dependentes" :key="index" :id-data="index"
                    :dependente="input.dependente" />
            </div>

            <div class="form-control">
                <button type="submit">Enviar formulário</button>
            </div>

        </form>
    </div>
</template>
<script>
import { mask } from 'vue-the-mask'
import InputDadosPessoais from './InputDadosPessoais'
import InputDadosEndereco from './InputDadosEndereco'
import InputDadosDocumentacao from './InputDadosDocumentacao'
import InputDadosBancarios from './InputDadosBancarios'
import InputDependente from './InputDependente'
import mixins from '@/assets/mixins'

export default {
    directives: { mask },
    mixins: [mixins],
    components: {
        InputDadosEndereco,
        InputDadosPessoais,
        InputDadosDocumentacao,
        InputDadosBancarios,
        InputDependente
    },
    async created() {
        //define o papel
        this.role = this.$route.meta.role;
        this.key = this.$route.params.key;

        //Verifica se a key é valida e o formulário ainda não foi preenchido
        this.verifica_key();

        //Carrega as ufs
        await this.$store.dispatch('load-ufs')
        await this.$store.dispatch('load-municipios');

        if (this.role == 'dp') {
            this.$store.dispatch('change-loading', true);
        }

        // Evento para salvar os campos do formulário no localstorage
        window.addEventListener('beforeunload', () => {
            var form = document.querySelector('form');
            const elements = form.elements;
            var data = this.serialize(form);

            // Formata os dependentes
            data.dependentes = this.get_dependentes(data);
            data.key = this.key;
            data.role = this.role;

            // Salva os dados no localstorage
            localStorage.setItem('form', JSON.stringify(data));
        });

        // Carrega os dados do localstorage se o role for candidato
        setTimeout(() => {
            this.carrega_formulario_localstorage()

        }, 500);

    },
    mounted() {
    },
    data: () => ({
        count_dependentes: 0, // Default dependentes
        dependentes: [], //
        role: null,
        key: null,
        debug: false,
        config: {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        },
        form: {
            dados_pessoais: {
                estado_civil: ""
            }
        },
    }),
    methods: {
        //Método para adicionar dependentes
        add_dependente(dependente) {
            this.dependentes = [];

            if (dependente == undefined) {
                dependente = {
                    nome_completo: null
                }
            }


            for (let i = 0; i < this.count_dependentes; i++) {
                this.dependentes.push({
                    id: `${this.count_dependentes + 1}`,
                    dependente: dependente
                });
            }
        },
        //Função para verificar se a key é valida e se o formulário não foi preenchido ainda
        verifica_key() {

            if (this.key == "123")
                return false;

            // Exibe o loading
            this.$store.dispatch('change-loading', true);

            let config = {
                method: 'get',
                url: `/admission-form/key/${this.key}`,
            };

            this.axios.request(config)
                .then((response) => {
                    const statusCode = response.status;

                    // Caso não tenha inserido, prossegue com o preenchimento
                    if (statusCode === 200) {
                        this.$store.dispatch('change-loading', false);
                        return true;
                    } else {
                        alert("Erro ao verificar formulário!");
                    }


                })
                .catch((error) => {
                    console.log(error);

                    const statusCode = error.response.status;

                    // Caso o formulário já tenha sido inserido, é redirecionado para a página finalizado
                    if (statusCode === 409 && this.role === "candidato" && !this.debug) {
                        this.$router.push('/finalizado');
                    }

                    // Se o formulário já está cadastrado, carrega os dados dele
                    if (statusCode === 409 && this.role === "dp") {
                        setTimeout(() => {
                            this.carrega_formulario();
                        }, 2000);
                    }

                    // Exibe o alerta de erro
                    if (statusCode !== 409) {
                        alert("Erro ao verificar formulário!");
                    }
                })

        },
        //Função para validar o submit do formulário e trazer informações dependentes        
        submit(e) {
            e.preventDefault();
            var form = document.querySelector('form');

            //Pega os valores de todos os campos por id
            var data = this.serialize(form);

            // Formata os dependentes
            data.dependentes = this.get_dependentes(data);
            data.key = this.key;
            data.role = this.role;

            //alert(get_dependentes)
            //salvar_formulario
            this.salvar_formulario(data);

        },
        //Salva o formulário        
        salvar_formulario(data) {
            let config = {
                method: 'post',
                url: '/admission-form/',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            this.axios.request(config)
                .then((response) => {
                    if (response.data.status) {
                        alert("Formulário enviado com sucesso!");

                        if (this.role == "candidato") {
                            // Redireciona para a página de finalizado
                            this.$router.push('/finalizado');

                            // Limpa o localstorage
                            // localStorage.removeItem('form');
                        }else if(this.role == "dp"){
                            // Redireciona para a página de finalizado
                            alert("Formulário atualizado com sucesso!");
                        }
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        //Formata os dependentes
        get_dependentes(data) {
            var index = []

            // Loop em torno os campos
            for (const key in data) {

                // Filtra os campos de dependentes
                if (key.indexOf("cf_dependente") == -1) {
                    continue;
                }

                let split = key.split("_"); // Quebra a string aonde tem underline
                let id = split[split.length - 1]; // pega o ultimo número da string para usar como id
                split.pop(); // remove o ultimo elemento que é a id usada
                let key_no_id = split.join("_"); // gera uma nova jey sem o id

                // Verifica se é um novo index
                if (typeof index[id] === 'undefined') {
                    index[id] = {};
                }

                //Adiciona o valor do campo ao index junto com a key
                index[id][key_no_id] = data[key];
            }

            return index;
        },
        //Carrega os dados do formulário
        carrega_formulario() {
            //Params
            const data = {
                key: this.key,
                role: this.role
            };

            let config = {
                method: 'get',
                url: `/admission-form/${this.key}`,
            };

            this.axios(config)
                .then((response) => {
                    if (!response.data.status) {
                        alert(response.data.message);
                    }

                    //Receive data
                    const data = response.data.data;

                    var form = document.querySelector('form');

                    const elements = form.elements;
                    //Fixa o valor padrão nos campos por id
                    for (let i = 0, element; element = elements[i++];) {
                        var new_value = data[element.id.replace('cf_', '')];

                        //Se o valor não for invalido e o campo existir
                        if (new_value && element.id) {
                            element.value = new_value;
                        }
                    }

                    //Define estado civil
                    this.form.dados_pessoais.estado_civil = data.estado_civil;

                    //Atualiza a quantidade de filhos
                    this.$children[0].tem_filhos = data.tem_filhos;
                    // this.$children[0].estado_civil = data.estado_civil;

                    this.count_dependentes = data.dependentes.length;

                    for (const i in data.dependentes) {
                        const dependente = data.dependentes[i];
                        this.add_dependente(dependente);
                    }

                    //POG para rodar somente depois que o formulário foi executado
                    setTimeout(() => {
                        // Loop nos dependentes
                        for (const i in data.dependentes) {
                            const dependente = data.dependentes[i];

                            //Loop nas chaves do objeto dependente
                            for (const key in dependente) {
                                let id = `cf_dependente_${key}_${i}`;
                                let el = document.getElementById(id);

                                // verifica se o existe
                                if (el) {
                                    el.value = dependente[key];
                                }
                            }

                            //POG: para o campo de dependete ir porque
                            //a nomeclatura é diferente dos demais campos
                            document
                                .getElementById('cf_dependente_ir_' + i)
                                .value = dependente.dependente_ir;
                        }

                    }, 3000);

                    //Oculta o loading
                    this.$store.dispatch('change-loading', false);

                })
                .catch((error) => {
                    console.log(error);
                });


        },
        carrega_formulario_localstorage() {
            if (this.role != "candidato") {
                return false;
            }
            var data = localStorage.getItem('form');
            if (!data) {
                return false;
            }

            data = JSON.parse(data);

            var formData = document.querySelector('form');
            if (!formData) {
                // debugger;
                return false;
            }
            const elements = formData.elements;
            //Fixa o valor padrão nos campos por id
            for (const element of elements) {

                // Nome do campo, basicamente com o cf_ na frente
                var new_value = data[element.id];

                // Nome interno do campo
                var element_id = element.id.replace('cf_', '');

                //Se o valor não for invalido e o campo existir
                if (new_value && element.id) {
                    element.value = new_value;
                }

            }

            //Define estado civil
            this.form.dados_pessoais.estado_civil = data.cf_estado_civil;
            this.form.dados_pessoais.estado_de_nascimento = data.cf_estado_de_nascimento;
            // setTimeout(() => {
            this.form.dados_pessoais.cidade_de_nascimento = data.cf_cidade_de_nascimento;
            // }, 3000);

            //Atualiza a quantidade de filhos
            this.$children[0].tem_filhos = data.cf_tem_filhos;

            this.count_dependentes = data.cf_count_dependentes;

            for (const i in data.dependentes) {
                const dependente = data.dependentes[i];
                this.add_dependente(dependente);
            }

            //POG para rodar somente depois que o formulário foi executado
            setTimeout(() => {
                // Loop nos dependentes
                for (const i in data.dependentes) {
                    const dependente = data.dependentes[i];

                    //Loop nas chaves do objeto dependente
                    for (const key in dependente) {
                        let id = `${key}_${i}`;
                        let el = document.getElementById(id);

                        // verifica se o existe
                        if (el) {
                            el.value = dependente[key];
                        }
                    }

                    //POG: para o campo de dependete ir porque
                    //a nomeclatura é diferente dos demais campos
                    document
                        .getElementById('cf_dependente_ir_' + i)
                        .value = dependente.cf_dependente_ir;
                }

            }, 3000);

        }
    }
}
</script>
<style lang="scss">
#CandidatoForm {
    .box-loading {
        position: relative;

        .loading {
            position: absolute;
            left: -34px;
            top: -2px;
            width: 31px;
        }
    }
}
</style>