export default {
    methods: {

        // Order string 
        orderStr(arr, param) {
            return arr.sort(function (a, b) {
                return a[param] < b[param] ? -1 : a[param] > b[param] ? 1 : 0;
            });
        },
        // Function to serialize
        serialize(form) {

            const elements = form.elements;
            const formData = {};

            for (let i = 0, element; element = elements[i++];) {
                //Evita objetos vazios
                if (element.id == "") {
                    continue;
                }

                if (element.type === 'checkbox') {
                    // Verifica se foi checked
                    if (element.checked) {
                        formData[element.id] = 'checked';
                    } else {
                        formData[element.id] = '';
                    }
                } else if (element.type === 'file') {
                    continue;
                } else {
                    //Veirifica o valor do campo não é vazio
                    if (element.value != "") {
                        
                        //Se for um select não trata o valor para o carregamento
                        if(element.type=="select-one"){
                            formData[element.id] = element.value;
                        }else{
                            formData[element.id] = this.retira_acentos(element.value.toUpperCase());
                        }
                    } else {
                        formData[element.id] = element.value;
                    }
                }
            }

            return formData;
        },
        validation(form) {
            const elements = form.elements;
            const errors = {};

            for (let i = 0, element; element = elements[i++];) {
                //Evita objetos vazios
                if (!element.required) {
                    continue;
                }

                if (element.value == "") {
                    errors.push(`O campo ${element.id} é obrigatório.`);
                }

            }

            return errors;
        },
        retira_acentos(str) {

            var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
            var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
            var novastr = "";
            for (var i = 0; i < str.length; i++) {
                var troca = false;
                for (var a = 0; a < com_acento.length; a++) {
                    if (str.substr(i, 1) == com_acento.substr(a, 1)) {
                        novastr += sem_acento.substr(a, 1);
                        troca = true;
                        break;
                    }
                }
                if (troca == false) {
                    novastr += str.substr(i, 1);
                }
            }
            return novastr;
        }
    }
};