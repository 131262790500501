<template>
  <div class="home">
    <Nav />
    <CandidatoForm />
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from '@/components/commons/Nav.vue'
import CandidatoForm from '@/components/admissao/CandidatoForm.vue'

export default {
  name: 'Home',
  components: {
    Nav,
    CandidatoForm
  }
}
</script>