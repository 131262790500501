<template>
    <div>
        <div class="form-control">
            <label for="cf_banco">Banco:</label>
            <input id="cf_banco" name="cf_banco">
        </div>
        <div class="form-control">
            <label for="cf_numero_da_agencia">Número da agência:</label>
            <input id="cf_numero_da_agencia" name="cf_numero_da_agencia"  v-mask="['####', '#####', '######']">
        </div>
        <div class="form-control">
            <label for="cf_numero_da_conta" >Número da conta:</label>
            <input id="cf_numero_da_conta" name="cf_numero_da_conta" v-mask="['###-#', '####-#', '#####-#', '######-#']">
        </div>
        <div class="form-control">
            <label for="cf_tipo_de_conta">Tipo de conta:</label>
            <input id="cf_tipo_de_conta" name="cf_tipo_de_conta">
        </div>
        <div class="form-control">
            <label for="cf_corrente">Corrente</label>
            <input id="cf_corrente" name="cf_corrente">
        </div>
        <div class="form-control">
            <label for="cf_poupanca">Poupança</label>
            <input id="cf_poupanca" name="cf_poupanca">
        </div>
    </div>
</template>
<script>
import {mask} from 'vue-the-mask'
export default {
    directives: {mask},
}
</script>