import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
// import mixins from './assets/mixins'

Vue.config.productionTip = false

//Global css
require('./assets/style.scss');

// Mask
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

// Import axios
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Definir a chave da API no cabeçalho de todas as requisições
axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY;


 
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  // mixins: [mixins],
  render: function (h) { return h(App) }
}).$mount('#app')
